import { render, staticRenderFns } from "./EstablishmentAccountings.vue?vue&type=template&id=64aab7be&scoped=true&"
import script from "./EstablishmentAccountings.vue?vue&type=script&lang=js&"
export * from "./EstablishmentAccountings.vue?vue&type=script&lang=js&"
import style0 from "./EstablishmentAccountings.vue?vue&type=style&index=0&id=64aab7be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64aab7be",
  null
  
)

export default component.exports