<template>
  <b-list-group class="balance-list">
    <b-list-group-item
      v-for="(accounting, index) in formattedColumns"
      :key="index"
      class=""
    >
      <b-badge variant="light-warning whitespace-normal text-break m-0">
        <span class="font-weight-bold">{{
          accounting.account_type | accountType
        }}</span>
      </b-badge>

      <div class="badges-flex">
        <div class="d-flex justify-content-between align-items-center">
          <small>Saldo a favor:</small>
          <b-badge variant="light-primary">
            <small>$ {{ accounting.balance }}</small>
          </b-badge>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <small>Saldo deudor:</small>
          <b-badge variant="light-primary">
            <small>$ {{ accounting.loan_balance }}</small>
          </b-badge>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <small>Límite de crédito:</small>
          <b-badge variant="light-primary">
            <small>$ {{ accounting.loan_limit }}</small>
          </b-badge>
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  components: {},
  props: {
    formattedColumns: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.badges-flex {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
