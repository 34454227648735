<template>
  <b-table
    class="text-center position-relative balance-table"
    :items="formattedColumns"
    :fields="tableColumns"
    responsive
    primary-key="id"
    show-empty
    empty-text="No existen registros aún"
  >
    <template #cell(balance)="row">
      <b-badge variant="light-success" class="mb-1">
        <span class="font-weight-bold">${{ row.value }}</span>
      </b-badge>
    </template>

    <template #cell(loan_balance)="row">
      <b-badge variant="light-danger" class="mb-1">
        <span class="font-weight-bold">${{ row.value }}</span>
      </b-badge>
    </template>

    <template #cell(loan_limit)="row">
      <b-badge variant="light-info" class="mb-1">
        <span class="font-weight-bold">${{ row.value }}</span>
      </b-badge>
    </template>

    <template #cell(account_type)="row">
      <b-badge variant="light-warning" class="mb-1">
        <span class="font-weight-bold">{{ row.value | accountType }}</span>
      </b-badge>
    </template>
  </b-table>
</template>

<script>
export default {
  components: {},
  props: {
    formattedColumns: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
