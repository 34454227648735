<template>
  <div>
    <b-tabs>
      <b-tab title="Ver balances" active>
        <establishment-accountings-list
          :formattedColumns="formattedColumns"
          :tableColumns="tableColumns"
        />

        <establishment-accountings-table
          :formattedColumns="formattedColumns"
          :tableColumns="tableColumns"
        />

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Mostrando {{ pagination.count }} de
                {{ pagination.total_objects }} registros</span
              >
            </b-col>
            <!-- Fake Pagination DO NOT replace -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="pagination.page"
                :total-rows="pagination.total_objects"
                :per-page="pagination.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <b-tab title="Agregar saldo">
        <b-row>
          <b-col cols="12">
            <b-link
              :to="{
                name: 'walleat-add-establishment-credit',
                params: { establishment_id: $route.params.id },
              }"
            >
              <b-card>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>CoDi</h3>
                  <b-img
                    :src="require('@/assets/images/logo/CODI.svg')"
                    height="90"
                    class="ml-1"
                  />
                </div>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import EstablishmentAccountingsList from "./EstablishmentAccountingsList.vue";
import EstablishmentAccountingsTable from "./EstablishmentAccountingsTable.vue";

export default {
  components: {
    EstablishmentAccountingsList,
    EstablishmentAccountingsTable,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
        count: 0,
      },
      tableColumns: [
        {
          key: "balance",
          label: "Saldo a favor",
          sortable: true,
        },
        {
          key: "loan_balance",
          label: "Saldo deudor",
          sortable: true,
        },
        {
          key: "loan_limit",
          label: "Límite de crédito",
          sortable: true,
        },
        {
          key: "account_type",
          label: "Tipo de cuenta",
          sortable: true,
        },
      ],
    };
  },

  beforeMount() {
    if (!this.currentEstablishment || !this.currentEstablishment.id) {
      this.fetchEstablishment(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters("establishments", ["currentEstablishment"]),
    formattedColumns() {
      if (!this.currentEstablishment.accountings) return [];

      this.pagination.total_objects =
        this.currentEstablishment.accountings.length;

      const startIndex = (this.pagination.page - 1) * this.pagination.per_page;
      const endIndex = startIndex + this.pagination.per_page;
      this.pagination.count = this.currentEstablishment.accountings.slice(
        startIndex,
        endIndex
      ).length;

      return this.currentEstablishment.accountings.slice(startIndex, endIndex);
    },
  },

  methods: {
    ...mapActions("establishments", ["fetchEstablishment"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.balance-table {
  display: none;

  @media (min-width: 767.98px) {
    display: block;
  }
}

.balance-list {
  display: block;

  @media (min-width: 767.98px) {
    display: none;
  }
}
</style>
